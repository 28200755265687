<template>
    <div>
        <Preloader v-if='loader' />
        <h4>Платежи</h4>

        <div class='filter__container pt-2 pb-3'>
            <div class='row mb-4'>
                <div class='input-group col-2'>
                    <input type='search' class='form-control h-100' placeholder='Введите имя или телефон' :value='search' @input='debounceSearch'>
                </div>

                <div class='col-3 text-right'>
                    <Multiselect
                        v-if='statuses && statuses.length > 0'
                        class='custom__multiselect'
                        :multiple='true'
                        selectLabel='нажмите чтобы выбрать'
                        deselectLabel='нажмите чтобы убрать'
                        tagPosition='bottom'
                        :tagable='true'
                        label='value'
                        track-by='id'
                        placeholder='Выберите статус'
                        :options='statuses'
                        :value='selectedStatuses'
                        @input='handleStatusSelect'
                    >
                        <template slot='tag' slot-scope='props'>
                            <b-badge class='mr-1' :variant='props.option.badge.css' pill>
                                {{ props.option.value }}
                                <a href='#' @click.prevent='props.remove(props.option)'>
                                    <i class='fa fa-close'></i>
                                </a>
                            </b-badge>
                        </template>
                        <template slot='option' slot-scope='props'>
                            <b-badge :variant='props.option.badge.css' pill>
                                {{ props.option.value }}
                            </b-badge>
                        </template>
                    </Multiselect>
                </div>

                <div class='col-3 text-right'>
                    <Multiselect
                        v-if='providers && providers.length > 0'
                        class='custom__multiselect'
                        :multiple='true'
                        selectLabel='нажмите чтобы выбрать'
                        deselectLabel='нажмите чтобы убрать'
                        tagPosition='bottom'
                        :tagable='true'
                        label='value'
                        track-by='id'
                        placeholder='Выберите кассу'
                        :options='providers'
                        :value='selectedProviders'
                        @input='handleProvidersSelect'
                    >
                        <template slot='tag' slot-scope='props'>
                            <b-badge class='mr-1' variant='primary' pill>
                                {{ props.option.value }}
                                <a href='#' class='text-white' @click.prevent='props.remove(props.option)'>
                                    <i class='fa fa-close'></i>
                                </a>
                            </b-badge>
                        </template>
                        <template slot='option' slot-scope='props'>
                            <b-badge variant='gray' pill>
                                {{ props.option.value }}
                            </b-badge>
                        </template>
                    </Multiselect>
                </div>
                <div class='input-group col-2 flex-nowrap'>
                  <VCalendar
                      class='teacher-salaries-section-calendar'
                      mode='date'
                      locale="ru"
                      is-range
                      :popover="{ visibility: 'focus' }"
                      :masks="{ input: ['DD.MM.YYYY']}"
                      @input='handleDateSelect'
                      v-model='date'
                  >
                    <template v-slot='{ togglePopover }'>
                      <div @click='togglePopover()'>
                        <input
                            class='form-control'
                            :class="{'hidden': !date.start}"
                            :value='getDateRange()'
                            readonly
                        />
                      </div>
                    </template>
                  </VCalendar>
                    <button @click="resetDateRange" class="teacher-salaries-section-calendar-reset" title='Сбросить дату'><i class='fa fa-close'></i></button>
                </div>

                <div class='flex-fill py-2 px-5 d-flex align-items-center'>
                    <a href='#' @click.prevent='handleClearFilters' class='w-100 text-right' title='Очистить фильтр'>
                        <i class='fa fa-close'></i>
                    </a>
                </div>
            </div>
            <div class="row mb-3">
              <div class='col-2 input-group text-left align-items-center'>
                <b-form-checkbox
                    id="checkbox-view-canceled-lesson"
                    v-model="isShowFirstPaid"
                    name="checkbox-view-canceled-lesson"
                    :checked="isShowFirstPaid"
                    @change="handleShowFirstPaidChange"
                >
                  Только первый платеж
                </b-form-checkbox>
              </div>
            </div>
        </div>

        <div class='row mb-3'>
            <div v-if='stats' class='stats col-8'>
                <div v-if="stats.month" class='stats-item'>
                    <div class="pb-2 fw-semi-bold text-center">За месяц:</div>
                    <div v-for="(item, key) in stats.month" :key="key" class="item-currency">
                        <span>{{ parseInt(item.amount) | readableSum }}</span> <span>{{item.currency}}</span>
                    </div>
                </div>
                <div v-if="stats.last_30_days" class='stats-item'>
                    <div class="pb-2 fw-semi-bold text-center">За 30 дней:</div>
                    <div v-for="(item, key) in stats.last_30_days" :key="key" class="item-currency">
                        <span>{{ parseInt(item.amount) | readableSum }}</span> <span>{{item.currency}}</span>
                    </div>
                </div>
                <div v-if="stats.week" class='stats-item'>
                    <div class="pb-2 fw-semi-bold text-center">За неделю:</div>
                    <div v-for="(item, key) in stats.week" :key="key" class="item-currency">
                        <span>{{ parseInt(item.amount) | readableSum }}</span> <span>{{item.currency}}</span>
                    </div>
                </div>
                <div v-if="stats.period" class='stats-item'>
                  <div class="pb-2 fw-semi-bold text-center">За выбраный период:</div>
                  <div v-for="(item, key) in stats.period" :key="key" class="item-currency">
                    <span>{{ parseInt(item.amount) | readableSum }}</span> <span>{{item.currency}}</span>
                  </div>
                </div>
                <div v-if="stats.total" class='stats-item'>
                    <div class="pb-2 fw-semi-bold text-center">Все время:</div>
                    <div v-for="(item, key) in stats.total" :key="key" class="item-currency">
                        <span>{{ parseInt(item.amount) | readableSum }}</span> <span>{{item.currency}}</span>
                    </div>
                </div>
            </div>

            <div class='col-4 ml-auto text-right'>
                <router-link
                    to='/transactions-create'
                    title='Создать платеж'
                    class='button_add button_add--height-auto'
                >
                    Создать платеж
                </router-link>
            </div>
        </div>

        <div class='export__container pb-3'>
          <div class='row mb-3'>
            <div class='col-3'>
              <a class="btn btn-success" :href="getLinkExcelDownload">Экспортировать в Excel</a>
            </div>
          </div>
        </div>

      <transactions-table-component
          v-if="transactionsData && Object.keys(transactionsData).length"
          :table-data="transactionsData" :is-editable="true"
      />
    </div>
</template>

<script>
import { debounce } from 'debounce';
import Multiselect from 'vue-multiselect';
import VCalendar from "v-calendar/lib/components/date-picker.umd";
import dayjs from "dayjs";
import TransactionsTableComponent from "@/pages/Admin/Transactions/Components/TransactionsTableComponent.vue";
import Preloader from '@/components/Preloader';
import customParseFormat from "dayjs/plugin/customParseFormat";

export default {
    name: 'TransactionList',
    components: {
      TransactionsTableComponent,
      VCalendar, Preloader, Multiselect },
    data() {
        return {
            baseUrl: process.env.VUE_APP_API_BASE_URL,
            date: {
                start: null,
                end: null
              // start: dayjs().set('date', 1).format('YYYY-MM-DD'),
              // end: dayjs().endOf('month').format('YYYY-MM-DD'),
            },
            currentPage: 1,
            loader: false,
            search: null,
            selectedStatuses: [],
            statuses: [],
            transactionsData: {},
            badgeMap: {
                'в обработке (успех)': {
                    title: 'заявка обработана деньги у клиента списаны, задержка подтверждения',
                    css: 'success',
                },
                'ждет подтвержд': {
                    title: 'ждет подтверждения магазина',
                    css: 'yellow',
                },
                'в ожидании оплаты': {
                    title: 'новая заявка, клиент еще не оплатил',
                    css: 'gray',
                },
                'отказ по заявке': {
                    title: 'отказано банком по какой либо причине или платеж не прошел',
                    css: 'danger',
                },
                'не оплачен': {
                    title: 'не оплачен',
                    css: 'danger',
                },
                'отменен': {
                    title: 'отменен',
                    css: 'danger',
                },
                'успешная оплата': {
                    title: 'успешная оплата',
                    css: 'success',
                },
                'возврат': {
                    title: 'возврат товара',
                    css: 'yellow',
                },
            },
            stats: null,
            providers: null,
            selectedProviders: null,
            isShowFirstPaid: false
        };
    },
    computed: {
      getLinkExcelDownload() {
        let queries = '';

        for (const [key, value] of Object.entries({ ...this.$route.query })) {
          if (value !== null) {
            if (queries === '') {
              queries += `?${key}=${value}`
              continue
            }
            queries += `&${key}=${value}`
          }
        }

        return `${this.baseUrl}/crm/transactions/export-excel${queries}`
      },
    },
    async beforeRouteUpdate(to, from, next) {
        this.loader = true;
        await this.fetchData(to.query);
        await this.fetchStats(to.query);
        this.loader = false;
        await next();
    },
    async created() {
        this.loader = true;

        const routeDateFrom = this.$route.query?.date_from;
        const routeDateTo = this.$route.query?.date_to;

        console.log(routeDateFrom)

        if (routeDateFrom && routeDateTo) {
          dayjs.extend(customParseFormat);
          if (routeDateFrom) this.date.start = dayjs(routeDateFrom).format('YYYY-MM-DD');
          if (routeDateTo) this.date.end = dayjs(routeDateTo).format('YYYY-MM-DD');
        } else {
          // await this.fillDataQuery();
        }

        await Promise.all([
            this.fetchStatuses(),
            this.fetchProviders(),
            this.fetchData(this.$route.query),
            this.fetchStats(this.$route.query)
        ]);

        this.fillFiltersOnInit();

        this.loader = false;
    },
    methods: {
        getDateRange() {
            if ((this.date.start && (Object.keys(this.date.start).length > 0) && (this.date.end && Object.keys(this.date.end).length > 0))) {
                return `с ${this.formatDate(this.date.start)} по ${this.formatDate(this.date.end)}`
            }

            return `Дата не выбрана`
        },
        async resetDateRange() {
            this.date.start = null;
            this.date.end = null;
            let query = Object.assign({}, this.$route.query);
            delete query.date_from;
            delete query.date_to;
            await this.$router.push({ query });
        },
        formatDate(date) {
          return dayjs(date).format('DD.MM.YYYY');
        },
        getConfirmClass(studentId, fromProfile) {
            let classes = '';

            if (!studentId) {
                classes = 'not-confirm';
            }

            if (fromProfile) {
                classes = 'from-profile';
            }

            return classes;
        },
        async fetchData(query = null) {
            console.log(query)
            try {
                const { data } = await this.$axios.get('crm/transactions', { params: query });
                this.transactionsData = data;
                this.loader = false;
            } catch (ex) {
                this.loader = false;
                console.log('cant fetch data: ' + ex);
            }
        },
        async fetchStatuses() {
            try {
                const { data } = await this.$axios.get('crm/transactions/get-statuses');

                const statuses = [];
                if (data) {
                    for (const key of Object.keys(data)) {
                        statuses.push({ id: key, value: data[key], badge: this.badgeMap[data[key]] });
                    }
                }
                this.statuses = statuses;
            } catch (ex) {
                console.log('cant fetch statuses: ' + ex);
            }
        },
        async fetchStats(query) {
            try {
                const { data } = await this.$axios.get('crm/transactions/get-stats', {
                  params: {...query}
                });
                this.stats = data;
            } catch (ex) {
                console.log('cant fetch stats: ', ex);
            }
        },
        async fetchProviders() {
            try {
                const { data } = await this.$axios.get('crm/transactions/get-providers');
                const providers = [];

                if (data) {
                    for (const key of Object.keys(data)) {
                        providers.push({ id: key, value: data[key] });
                    }
                }
                this.providers = providers;
            } catch (ex) {
                console.log('cant fetch providers: ', ex);
            }
        },
        fillFiltersOnInit() {
            if (this.$route.query && Object.keys(this.$route.query)) {
                const query = this.$route.query;

                if (query.query) {
                    this.search = query.query;
                }

                if (query.statuses && this.statuses && this.statuses.length) {
                    const ids = query.statuses.split(',');
                    const statuses = [];

                    this.statuses.forEach(item => {
                        if (ids.includes(item.id)) {
                            statuses.push({
                                id: item.id,
                                value: item.value,
                                badge: this.badgeMap[item.value],
                            });
                        }
                    });

                    this.selectedStatuses = statuses;
                }

                if (query.providers && this.providers && this.providers.length) {
                    const ids = query.providers.split(',');
                    const providers = [];

                    this.providers.forEach(item => {
                        if (ids.includes(item.id)) {
                            providers.push({
                                id: item.id,
                                value: item.value,
                            });
                        }
                    });

                    this.selectedProviders = providers;
                }

                if (query.first_paid) {
                  this.isShowFirstPaid = query.first_paid
                }
            }
        },
        async fillDataQuery() {
            if (!this.$route.query.date_from) {
                let query = Object.assign({}, this.$route.query, { date_from: this.date.start, date_to: this.date.end, page: '1' });
                await this.$router.push({ query });
            }
        },
        debounceSearch: debounce(async function(e) {
            const val = e.target.value;
            const query = Object.assign({}, this.$route.query, { query: val, page: '1' });
            this.search = val;
            await this.$router.push({ query });
        }, 2000),
        async handleStatusSelect(values) {
            this.selectedStatuses = values;
            let query;

            if (values.length) {
                const ids = values.map(i => i.id).join(',');
                query = Object.assign({}, this.$route.query, { statuses: ids, page: '1' });
            } else {
                query = Object.assign({}, this.$route.query);
                delete query.statuses;
                delete query.page;
            }

            await this.$router.push({ query });
        },
        async handleProvidersSelect(values) {
            this.selectedProviders = values;
            let query;

            if (values.length) {
                const ids = values.map(i => i.id).join(',');
                query = Object.assign({}, this.$route.query, { providers: ids, page: '1' });
            } else {
                query = Object.assign({}, this.$route.query);
                delete query.providers;
                delete query.page;
            }

            await this.$router.push({ query });
        },
        async handlePageSelect(bvEvent, page) {
            if (page) {
                const query = Object.assign({}, this.$route.query, { page });
                await this.$router.push({ query: query });
            }

            return false;
        },
        async handleDateSelect(values) {
          let query
          if (values) {
            this.date.start = dayjs(values.start).format('YYYY-MM-DD')
            this.date.end = dayjs(values.end).format('YYYY-MM-DD')
            query = Object.assign({}, this.$route.query, { date_from: this.date.start, date_to: this.date.end, page: '1' });
          } else {
            query = Object.assign({}, this.$route.query);
            delete query.start;
            delete query.end;
          }

          await this.$router.push({ query });
        },
        async handleClearFilters() {
            this.selectedStatuses = [];
            this.selectedProviders = [];
            this.$set(this.date, 'start', dayjs().set('date', 1).format('YYYY-MM-DD'));
            this.$set(this.date, 'end', dayjs().endOf('month').format('YYYY-MM-DD'));
            this.search = null;
            await this.$router.push({ query: {date_from: this.date.start, date_to: this.date.end} });
        },
        async handleShowFirstPaidChange(val) {
          this.isShowFirstPaid = val;
          let query;

          if (val) {
            query = Object.assign({}, this.$route.query, { first_paid: val, page: '1' });
          } else {
            query = Object.assign({}, this.$route.query, {page: 1});
            delete query.first_paid;
            delete query.page;
          }

          await this.$router.push({ query });
        },
    },
};
</script>

<style lang='scss' scoped>
.btn .glyphicon {
    top: 0 !important;
}

.badge {
    border-radius: 5px
}

.button_add--height-auto {
    height: auto;
}

.teacher-salaries-section-calendar{
  border-radius: 5px 0 0 5px;
  border: 1px solid #e8e8e8;
  .form-control{
    border: none;
    min-height: 40px;
    color: #29323a;
    display: block;
    background: #fff;
    font-size: 14px;
    cursor: pointer;
  }
}

.teacher-salaries-section-calendar-reset{
    border-radius: 0 5px 5px 0;
    border: 1px solid #e8e8e8;
    border-left: none;
    min-height: 40px;
    color: #1A86D0;
    display: block;
    background: #fff;
    font-size: 14px;
    cursor: pointer;
}


.stats {
    display: flex;


    &-item {
        display: block;
        padding: 0.75rem 1rem;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, .125);
        border-right-width: 0;
        min-width: 200px;

        .item-currency {
            display: flex;
            justify-content: space-between;
        }

        &:hover {
            transition: background-color .3s ease;
            background-color: rgba(0, 0, 0, 0.075);
        }

        &:first-child {
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;
        }

        &:last-child {
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
            border-right-width: 1px;
        }
    }
}
</style>